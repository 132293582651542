var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "settings-item" },
    [
      _c("v-select", {
        staticClass: "v-select--theme--default video-check-card__select mt-3",
        attrs: {
          value: _vm.microphone,
          items: _vm.devices,
          "hide-details": "",
          dense: "",
        },
        on: { input: _vm.deviceInputHandler },
      }),
      _c(
        "v-flex",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !!_vm.audioTestTrack,
              expression: "!!audioTestTrack",
            },
          ],
          staticClass: "settings-sound-indicator-wrap mt-5",
        },
        [
          _c("div", { staticClass: "settings-sound-indicator-container" }, [
            _c("div", {
              ref: "left_bar",
              staticClass: "settings-sound-indicator",
            }),
          ]),
          _c("div", { staticClass: "settings-sound-indicator-container" }, [
            _c("div", {
              ref: "center_bar",
              staticClass: "settings-sound-indicator",
            }),
          ]),
          _c("div", { staticClass: "settings-sound-indicator-container" }, [
            _c("div", {
              ref: "right_bar",
              staticClass: "settings-sound-indicator",
            }),
          ]),
        ]
      ),
      _c("audio", { ref: "audio" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }