import { ref } from "@vue/composition-api"

const canvas = ref(null)

const canvasToJpg = canvas => {
  return new Promise(resolve =>
    canvas.toBlob(blob => resolve(blob), "image/jpeg", 0.9)
  )
}

function drawImage(media, ctx, mirrored) {
  const canvas = ctx.canvas

  const mediaHeight = media.videoHeight || media.height
  const mediaWidth = media.videoWidth || media.width

  const hRatio = canvas.width / mediaWidth
  const vRatio = canvas.height / mediaHeight

  const ratio = Math.max(hRatio, vRatio)

  const scaledWidth = mediaWidth * ratio
  const scaledHeight = mediaHeight * ratio

  const centerShift_x = (canvas.width - scaledWidth) / 2
  const centerShift_y = (canvas.height - scaledHeight) / 2

  ctx.clearRect(0, 0, canvas.width, canvas.height)

  if (mirrored) {
    ctx.translate(canvas.width, 0)
    ctx.scale(-1, 1)
  }

  ctx.drawImage(
    media,
    0,
    0,
    mediaWidth,
    mediaHeight,
    centerShift_x,
    centerShift_y,
    scaledWidth,
    scaledHeight
  )
}

async function imageFromElement(el, mirrored = true) {
  const _canvas = canvas.value

  _canvas.height = el.videoHeight || 220
  _canvas.width = el.videoWidth || 220
  _canvas.getContext("2d")

  const ctx = _canvas.getContext("2d")
  drawImage(el, ctx, mirrored)

  return await canvasToJpg(ctx.canvas)
}

export default function useCanvasImage() {
  return {
    canvas,
    imageFromElement
  }
}
