<template>
  <div class="settings-item">
    <v-select
      :value="microphone"
      :items="devices"
      hide-details
      dense
      class="v-select--theme--default video-check-card__select mt-3"
      @input="deviceInputHandler"
    />

    <v-flex
      v-show="!!audioTestTrack"
      class="settings-sound-indicator-wrap mt-5"
    >
      <div class="settings-sound-indicator-container">
        <div class="settings-sound-indicator" ref="left_bar"></div>
      </div>
      <div class="settings-sound-indicator-container">
        <div class="settings-sound-indicator" ref="center_bar"></div>
      </div>
      <div class="settings-sound-indicator-container">
        <div class="settings-sound-indicator" ref="right_bar"></div>
      </div>
    </v-flex>

    <!-- Skip setSinkId -->
    <audio ref="audio" />
  </div>
</template>

<script>
import { TweenMax } from "gsap/TweenMax"
import { mapActions, mapState } from "vuex"

import { ActionTypes as TwilioModuleActionTypes } from "../../../../store/TwilioModule"

export default {
  name: "Microphone",
  components: {},
  props: ["user", "isOnboarding"],
  data() {
    return {
      audioTestTrack: null,
      analyser: null,
      dataArray: null
    }
  },

  computed: {
    ...mapState("twilio", ["microphones", "microphone"]),

    devices() {
      return this.microphones.map((device, index) => ({
        value: device.deviceId,
        text: device.label === "" ? `Microphones ${index + 1}` : device.label
      }))
    }
  },

  watch: {
    audioTestTrack(newValue, oldValue) {
      if (oldValue && oldValue !== newValue) {
        oldValue.detach(this.$refs.audio)
      }
      if (newValue && newValue !== oldValue) {
        newValue.attach(this.$refs.audio)
        try {
          const audioContext = new (window.AudioContext ||
            window.webkitAudioContext)()
          // create a source from our MediaStream
          const source = audioContext.createMediaStreamSource(
            this.$refs.audio.srcObject
          )
          // create an analyser object
          this.analyser = audioContext.createAnalyser()
          // how often to update
          this.analyser.fftSize = 256
          const bufferLength = this.analyser.frequencyBinCount
          this.dataArray = new Uint8Array(bufferLength)
          source.connect(this.analyser)
        } catch (e) {
          console.error(e.message)
        }
      }
    }
  },

  created() {
    TweenMax.ticker.addEventListener("tick", this.onTick)
  },

  mounted() {
    this.startMic()
  },

  beforeDestroy() {
    TweenMax.ticker.removeEventListener("tick", this.onTick)
    if (this.audioTestTrack) this.audioTestTrack.detach(this.$refs.audio)
  },

  methods: {
    ...mapActions("twilio", [
      TwilioModuleActionTypes.SET_MICROPHONE,
      TwilioModuleActionTypes.CREATE_LOCAL_AUDIO_TRACK,
      TwilioModuleActionTypes.UPDATE_LOCAL_AUDIO_TRACK
    ]),

    onTick() {
      if (this.analyser && this.dataArray) {
        const max = Math.max.apply(null, this.dataArray)
        this.analyser.getByteFrequencyData(this.dataArray)
        const percentage = parseInt((max / 255) * 100)

        if (this.$refs.left_bar) {
          this.$refs.left_bar.style.height = 80 - percentage + "%"
        }

        if (this.$refs.center_bar) {
          this.$refs.center_bar.style.height = 50 - percentage + "%"
        }

        if (this.$refs.right_bar) {
          this.$refs.right_bar.style.height = 70 - percentage + "%"
        }
      }
    },

    async startMic() {
      try {
        const track = await this[
          TwilioModuleActionTypes.CREATE_LOCAL_AUDIO_TRACK
        ]()

        this.audioTestTrack = track
      } catch (e) {
        console.error(e)
        this.micErrorHTML =
          "Yikes. You've got a microphone error. Please make sure you have one, close all applications that may use it and make sure your browser has access to the microphone."
      }
    },

    async deviceInputHandler(id) {
      this[TwilioModuleActionTypes.SET_MICROPHONE](id)
      await this.startMic()

      if (!this.isOnboarding) {
        this[TwilioModuleActionTypes.UPDATE_LOCAL_AUDIO_TRACK]()
      }
    }
  }
}
</script>

<style lang="scss">
.settings-sound-indicator-wrap {
  display: flex;
  justify-content: center;
  height: 170px;
  position: relative;
}

.settings-sound-indicator-container {
  height: 100%;
  position: relative;
  width: 42px;
  margin: 0 21px;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(244, 91, 71, 1) 0%,
      rgba(90, 174, 89, 0.989233193277311) 66%
    );
    z-index: 1;
  }
}

.settings-sound-indicator {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #393b42;
  z-index: 2;
}
</style>
