var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "camera-settings" },
    [
      _c("v-select", {
        staticClass: "camera-settings__select",
        attrs: {
          value: _vm.camera,
          items: _vm.devices,
          disabled: _vm.working,
          "hide-details": "",
          dense: "",
        },
        on: { input: _vm.onCameraInput },
      }),
      _vm.working
        ? _c(
            "div",
            { staticClass: "camera-settings__loader" },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "#F5665D", size: "80" },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.canShowMedia,
              expression: "canShowMedia",
            },
          ],
          staticClass: "camera-settings__media",
        },
        [
          _vm.videoTrack
            ? _c("UserVideo", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.image,
                    expression: "!image",
                  },
                ],
                ref: "video",
                staticClass: "camera-settings__media-item",
                attrs: { track: _vm.videoTrack },
                on: { ready: _vm.onReady },
              })
            : _vm._e(),
          _c("CanvasImage", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.image,
                expression: "image",
              },
            ],
            staticClass: "camera-settings__media-item",
          }),
        ],
        1
      ),
      _vm.error
        ? _c("div", { staticClass: "camera-settings__error" }, [
            _c("div", { staticClass: "camera-settings__error-message" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/login/warning.svg"),
                  alt: "onboarding-error-icon",
                },
              }),
              _c("span", [_vm._v(_vm._s(_vm.error))]),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }