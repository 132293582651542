<template>
  <div class="camera-settings">
    <v-select
      :value="camera"
      :items="devices"
      :disabled="working"
      hide-details
      dense
      class="camera-settings__select"
      @input="onCameraInput"
    />

    <div v-if="working" class="camera-settings__loader">
      <v-progress-circular indeterminate color="#F5665D" size="80" />
    </div>

    <div class="camera-settings__media" v-show="canShowMedia">
      <UserVideo
        v-if="videoTrack"
        v-show="!image"
        :track="videoTrack"
        class="camera-settings__media-item"
        ref="video"
        @ready="onReady"
      />
      <CanvasImage v-show="image" class="camera-settings__media-item" />
    </div>

    <div class="camera-settings__error" v-if="error">
      <div class="camera-settings__error-message">
        <img
          :src="require('@/assets/login/warning.svg')"
          alt="onboarding-error-icon"
        />
        <span>{{ error }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { ActionTypes as TwilioActionTypes } from "@/store/TwilioModule"

import { TwilioRoom } from "@/services/twilio.service"
import { WeDontSupportThisBrowser } from "@/exceptions"

import UserVideo from "@/components/GroupTeams/Common/User/UserVideo.vue"

import CanvasImage from "@/components/GroupTeams/Common/AudioVideoSettings/CanvasImage.vue"

const TWILIO_CONNECTION_TIMEOUT = 15000

const throwOnTimeout = (message = "Timeout", delay = 10000) =>
  new Promise((_, reject) => setTimeout(reject, delay, new Error(message)))

export default {
  name: "Camera",
  components: { UserVideo, CanvasImage },
  props: {
    image: {
      type: Boolean,
      default: false
    },
    camera: {
      type: String,
      required: true
    },
    cameras: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      working: true,
      videoTrack: null,
      error: null,
      devices: []
    }
  },
  beforeDestroy() {
    this.videoTrack?.stop()
  },
  computed: {
    canShowMedia() {
      return !this.working && !this.error
    }
  },
  watch: {
    working: {
      handler(value) {
        this.$emit("working", value)
      },
      immediate: true
    }
  },
  created() {
    this.devices = this.cameras.map((device, index) => ({
      value: device.deviceId,
      text: device.label || `Camera ${index + 1}`
    }))
  },
  mounted() {
    this.$nextTick(() => this.start())
  },
  methods: {
    ...mapActions("twilio", [
      TwilioActionTypes.SET_CAMERA,
      TwilioActionTypes.CREATE_LOCAL_VIDEO_TRACK
    ]),
    onReady() {
      this.working = false
      this.$emit("ready", this.$refs.video?.$el)
    },
    async onCameraInput(id) {
      this.$emit("onCameraChanged")

      this.videoTrack?.stop()

      this.videoTrack = null
      this.working = true
      this.error = null

      await this[TwilioActionTypes.SET_CAMERA](id)

      this.$emit("error", null)

      this.$nextTick(() => this.start())
    },
    async start() {
      this.working = true
      this.error = null
      try {
        if (!TwilioRoom.isSupportedBrowser())
          throw new WeDontSupportThisBrowser()

        this.videoTrack = await Promise.race([
          this[TwilioActionTypes.CREATE_LOCAL_VIDEO_TRACK](),
          throwOnTimeout(undefined, TWILIO_CONNECTION_TIMEOUT)
        ])
      } catch (e) {
        this.error = e.message
        this.working = false
        this.$emit("error", e)
      }
    }
  }
}
</script>

<style lang="scss">
.camera-settings {
  --media-item-size: 26vh;
  --media-item-max-size: 220px;
  padding-top: 16px;
  &__select {
    margin-bottom: 2vh !important;
  }

  &__media {
    text-align: center;
    margin-top: 48px;
  }

  &__media-item {
    border-radius: 4px;
    height: var(--media-item-size);
    width: var(--media-item-size);
    max-height: var(--media-item-max-size);
    max-width: var(--media-item-max-size);
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: var(--media-item-size);
    max-height: var(--media-item-max-size);
  }

  &__error {
    min-height: var(--media-item-max-size);
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__error-message {
    max-width: 310px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background-color: $connection-warning-bg;
    border-radius: 2px;

    img {
      width: 32px;
      height: auto;
      margin-right: 15px;
    }

    span {
      display: inline-block;
      font-size: 18px;
      line-height: 24px;
      font-weight: 400;
      color: $main-card-text;
    }
  }
}
</style>
