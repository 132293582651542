<template>
  <canvas
    ref="canvas"
    v-bind="$attrs"
    style="background: var(--primary-accent-color); border-radius: 4px"
  />
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import useCanvasImage from "./useCanvasImage"

export default defineComponent({
  setup() {
    const { canvas } = useCanvasImage()
    return {
      canvas
    }
  }
})
</script>
